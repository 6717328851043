
import {Options, Vue} from 'vue-class-component';
import noUiSlider, {PipsMode} from "nouislider";

@Options({
    props: {
        id: String,
        label: String,
        identifier: String,
    }
})

export default class FormFieldProblemRating extends Vue {

    protected selected = 1;
    protected sliderStepItems = [
            {
                label: 'keine/normale Probleme',
                value: 1
            },
            {
                label: 'leichte Probleme',
                value: 2
            },
            {
                label: 'mittlere Probleme',
                value: 3
            },
            {
                label: 'schwere Probleme',
                value: 4
            },
            {
                label: 'sehr schwere Probleme',
                value: 5
            }
        ]

    get selectedStepItem(): Record<string, string|number> | undefined {
        const selectedStepItem = this.sliderStepItems.find((item) => item.value === this.selected);
        return selectedStepItem;
    }

    public mounted(): void {
        const sliderElement: HTMLElement | unknown = this.$refs.sliderElement;
        if (sliderElement instanceof HTMLElement) {
            const slider = noUiSlider.create(sliderElement, {
                range: {
                    min: 1,
                    max: 5
                },
                step: 1,
                start: 1,
                pips: {
                    mode: PipsMode.Values,
                    values: this.sliderStepItems.map((sliderItem) => sliderItem.value),
                    density: 100
                }
            });
            slider.on('update', (value: (string | number)[]) => {
                this.selected = parseInt(value[0].toString());
            })
        }
    }

}
