<template>
    <div class="o-company">
        <h5>Besonderheiten und Ressourcen der Einrichtung</h5>

        <div class="o-company__section">
            <p>Breit gefächertes Angebot vollstationärer Hilfen im Alter von 2 Jahren bis Volljährigkeit inkl. Intensivwohngruppen, Mutter/Vater/Kind Angebote und Inobhutnahme</p>
            <p>Breit gefächerte und sehr differenzierte Angebote im teilstationären und ambulanten und schulischen Bereich u.a.:</p>
            <ul>
                <li>FAM/FSP</li>
                <li>Ambulantes Kriseninterventionsteam</li>
                <li>MST – Multisystemtherapie</li>
            </ul>
            <p>SBBZ Esent und berufschulisches Angebot <br>Kooperationen mit: </p>
            <ul>
                <li>Kinderzentrum Maulbronn und KJP,</li>
                <li>(Sucht-)Beratungsstellen</li>
                <li>Kinder- und Jugendpsychiatrische Praxis</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Umsetzung</h6>
            <p>Beratung durch Personen aus obigen Handlungsfeldern z.B. (FAM/FSP, MST, SBBZ,…) oder MA des Fachdienstes</p>
            <p>Betreuung in den benannten Angeboten</p>
        </div>

        <div class="o-company__section">
            <h6>Angebot</h6>
            <h7>Schnell:</h7>
            <ul>
                <li>Kurzfristige ION mit Clearing</li>
            </ul>
            <h7>Mittelfristig/belegungsabhängig:</h7>
            <ul>
                <li>Aufnahme in den vollstationären Angeboten, mit sofortiger SBBZ-Beschulung</li>
                <li>Flexible individuelle Betreuungssettings/Kostenabklärung notwendig</li>
                <li>Hilfen für (Teil-)Familien</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Mitarbeiterbegleitung</h6>
            <ul>
                <li>Gemeinsame Haltung aller Fachkräfte entwickeln, kommunizieren, leben, transportieren</li>
            </ul>
            <h7>Unterstützung in der herausfordernden Arbeit durch Leitung und Fachdienst:</h7>
            <ul>
                <li>Fortbildungen, Beratung, Begleitung</li>
                <li>Krisenplan entwickeln</li>
                <li>kreative Arbeitsbedingungen</li>
                <li>persönliche Grenzen der MAB herausarbeiten, wertschätzen und in der fachlichen, herausfordernden Arbeit berücksichtigen</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Grenzen</h6>
            <ul>
                <li>bisher wenig Erfahrung in individuellen, erlebnis-pädagogischen Projekten allerdings Erfahrung in der Alltagsintegration von Erlebnispädagogik</li>
                <li>JM mit Suchterkrankungen oder ausgeprägter geistiger Behinderung</li>
                <li>starke psychische Erkrankung</li>
            </ul>
        </div>

    </div>
</template>
