import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-form__step__title"
}
const _hoisted_2 = {
  key: 1,
  class: "c_form__step__children"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.children.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (child, index) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(child.component.name), _mergeProps({
                key: index,
                label: child.label,
                children: child.children
              }, child.component.props), null, 16, ["label", "children"]))
            }), 128))
          ], 1024))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}