<template>
    <div class="o-company">
        <h5>Besonderheiten und Ressourcen der Einrichtung</h5>

        <div class="o-company__section">
            <h6>Angebote</h6>
            <ul>
                <li>1 Wohngruppe / 9 Plätze für Mädchen und Jungen ab 12 Jahren</li>
                <li>1 Mädchenwohngruppe / 9 Plätze ab 12 Jahren</li>
                <li>4 Jugendwohngemeinschaften/a 3 Plätze ab 16 Jahren</li>
                <li>Inobhutnahme für Mädchen ab 12 Jahren</li>
                <li>Ambulante Hilfen / EB / SPFH</li>
                <li>SBBZ ESENT ab Klasse 5 mit Tagesgruppen und Berufsstarttraining</li>
                <li>3 Heilpädagogische Fördergruppen à 10 Plätze an Grundschulen</li>
                <li>Berufsorientierung in der Hauswirtschaft</li>
                <li>Auftakt: begleitete Ausbildungen in der Altenhilfe</li>
                <li>Psychologischer Fachdienst (PFD) und Konsiliar-Psychiater</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Ressourcen/Qualifizierung</h6>
            <ul>
                <li>Viele MA_innen mit systemischer Berater-Ausbildung, LO-Arbeit</li>
                <li>PFD mit Zulassung Verhaltenstherapie für Kinder, Ju, Erw.</li>
                <li>Supervisionen und Fallberatungen über externe Fachkräfte</li>
            </ul>

        </div>

        <div class="o-company__section">
            <h6>Kooperationspartner</h6>
            <ul>
                <li>AG §42 SGB 8</li>
                <li>AG Ambulante Hilfen</li>
                <li>Psychologische Beratungsstelle der Stadt Freiburg</li>
                <li>Temporärer anlassbezogener Verbund mit Ev. Jugendhilfe</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Umsetzung</h6>
            <ul>
                <li>Hohe Fachlichkeit und Beratungskompetenzen der Fachkräfte, Leitung, PFD bei Hilfeverläufen, Verlauf von IO, bei Krisenbewältigung</li>
                <li>Krisen- und Notfallpläne, Leitlinien</li>
                <li>Durchgängiges Gesamtkonzept der Einrichtung von Fördergruppen in Grundschulen, über Schule mit Tagesgruppen, Berufsstarttraining, Praktika, begleitete Ausbildungsmöglichkeiten in der Altenhilfe, stat. Angebote, Ambulante Hilfen</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Angebot</h6>
            <ul>
                <li><strong>Schnell</strong> – Fallberatung, Schulische Beratung, Blick von außen, IO nach Klärung der Lage, Kosten und Platz</li>
                <li><strong>Mittel</strong> – Schulplatz, Wohngruppenplatz, JWG-Platz, AH, Praktika, Ausbildungsplatz in der Altenhilfe</li>
                <li><strong>Langfristig</strong> – Hilfen incl. Wohnraum</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Mitarbeiterbegleitung</h6>
            <ul>
                <li>Belastbare MAB durch Fortbildungen, Beratung, Supervision, Fallberatung, Coaching, Entlastungen/Fürsorge</li>
                <li>Kriseninterventionsplan</li>
                <li>Rückhalt und Mittragen von Entscheidungen durch Leitung, Psych. FD, </li>
                <li>Durchgängige Erreichbarkeit eines Bereitschaftsdienstes mit Support, der auch vor Ort erfolgen kann</li>
                <li>Unkonventionelle Hilfen wie Einsatz Sicherheitsdienst</li>
                <li>attraktive Arbeitszeitmodelle, effektive Dienstplangestaltung, Auszeiten, Urlaubspläne</li>
                <li>Sicherheit geben, dass ein Scheitern das Team / den MAB und dessen Entscheidungen nicht grundsätzlich in Frage stellt, sondern die Frage gestellt wird, was wäre noch möglich gewesen …</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Grenzen</h6>
            <ul>
                <li><strong>Persönlich</strong> – abfragen und möglichen Ausgleich schaffen</li>
                <li><strong>Einrichtung</strong> – Drogen, Gewalt, psychiatrische Krankheitsbilder, junge Menschen mit körperlicher und geistiger Behinderung</li>
            </ul>
        </div>
    </div>
</template>
