
import {defineComponent, ref} from "vue";
import FormFieldText from '@/components/form/fields/FormFieldText.vue';
export default defineComponent({
    props: {
        id: String,
        label: String,
        identifier: String,
        value: String,
        specifyIfSet: {
            type: Boolean,
            default: false,
            required: false
        },
        specificationFieldLabel: {
            type: String,
            required: false
        },
        specificationFields: {
            type: Array,
            required: false
        }
    },
    components: {
        FormFieldText
    },
    setup: () => {
        const checked = ref(false);
        return {
            checked
        }
    },
    methods: {
        handleClick() {
            this.checked = !this.checked;
        }
    }
})
