import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-form__fieldset" }
const _hoisted_2 = {
  key: 0,
  class: "c-form__fieldset__title"
}
const _hoisted_3 = {
  key: 1,
  class: "c-form__fieldset__children"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.children.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (child, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(child.component.name), _mergeProps({
              key: index,
              label: child.label,
              id: child.id,
              identifier: child.identifier
            }, child.component.props), null, 16, ["label", "id", "identifier"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}