
import {Options, Vue} from 'vue-class-component';

@Options({
    props: {
        id: String,
        additionalClasses: String
    }
})
export default class Section extends Vue {

    protected container!: HTMLElement;
    protected isInViewport = false;

    async mounted(): Promise<void> {
        this.container = await this.$refs.sectionContainer as HTMLElement;
        const callback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (this.isInViewport !== entry.isIntersecting) {
                    this.isInViewport = entry.isIntersecting;
                    this.$emit('intersection-change', entry.isIntersecting, this.container.id);
                }
            })
        }
        if (this.container.hasAttribute('id')) {
            const observer = new IntersectionObserver(callback, {
                threshold: 0
            })
            observer.observe(this.container);
        }
    }

}
