
import {defineComponent, ref} from "vue";
import SweetScroll from 'sweet-scroll';
import TermsOfUse from "@/components/content/legal/TermsOfUse.vue";
import Imprint from "@/components/content/legal/Imprint.vue";

export default defineComponent({
    components: {TermsOfUse, Imprint},
    setup: () => {
        const date = new Date();
        const year = date.getFullYear();
        const isOpen = ref(false);
        const sweetScroll = new SweetScroll();
        const currentPage = ref<string | null>(null);
        return {
            year,
            isOpen,
            sweetScroll,
            currentPage
        }
    },
    methods: {
        async handleClick(e: Event, module: string) {
            e.preventDefault();
            this.currentPage = module;
            await this.$nextTick(() => {
                const target = this.$refs.pagesContainer as HTMLElement;
                this.sweetScroll.toElement(target, {
                    offset: -80
                });
            });
        }
    }
});

