
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        initOpen: {
            type: Boolean,
            default: false,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        logo: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            open: false
        }
    },
});

