<template>
    <div class="o-company">
        <h5>Besonderheiten und Ressourcen der Einrichtung</h5>

        <div class="o-company__section">
            <h7>Umfassendes stationäres Angebot in der Bodenseeregion:</h7>
            <ul>
                <li>koedukative Wohngruppen</li>
                <li>Mädchenwohngruppen</li>
                <li>Jungenwohngruppen</li>
                <li>verschiedene BJW-Standorte</li>
                <li>Intensivwohngruppen</li>
                <li>Schule am Heim (SBBZ ESENT) mit VAB</li>
                <li>ISE-Konzept "Ankerplatz"</li>
            </ul>
            <h7>Enge Kooperation mit der KJP mit suchtspezifischen Hilfen (Clean.Kids; Clean.Kick)</h7>
            <ul>
                <li>PIA-Sprechstunden vor Ort sowie Kriseninterventionsplanung</li>
                <li>Zertifizierte psychosoziale Einrichtung durch die DGTA</li>
                <li>Fachdienstberatung mit Modellen der Transaktionsanalyse, systemischen Ansätzen, Elternaktivierung, Lerntheorie u.a.</li>
                <li>Zusammenarbeit mit regionalen "Streetwork-Angeboten"</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Umsetzung</h6>
            <ul>
                <li>Beratungsangebote für Einrichtungen, Jugendämtern und ggf. Eltern/Familien vor Ort oder per Videokonferenz</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Angebot</h6>
            <ul>
                <li>Beratungsangebote und Auszeiten nach Absprache</li>
                <li>Gemeinsame fortbildungstage zum Thema</li>
            </ul>

        </div>

        <div class="o-company__section">
            <h6>Mitarbeiterbegleitung</h6>
            <ul>
                <li>die notwendige Sicherheit herstellen</li>
                <li>methodische Handwerkzeug mitgeben</li>
                <li>umfangreiches Fallverstehen durch Kombination von tiefenpsychologischen und systemischen Erklärungsansätzen ermöglichen</li>
                <li>Regelmäßige interne Fortbildungen und (zeitnahe) Supervisionen und Beratung in Krisen. </li>
                <li>Angemessene Vergütung und verlässliche Leitungsstrukturen innerhalb der Einrichtung </li>
                <li>Ausreichende Personalressourcen</li>
                <li>Weiterbildungsmöglichkeiten</li>
                <li>Erfolge würdigen, gemeinsam feiern</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Grenzen</h6>
            <ul>
                <li>Nur wenig interne Beschäftigungsmöglichkeiten, falls Schule nicht möglich</li>
                <li>Keine separierten Wohnräume für niederschwellige Angebote, derzeit nur innerhalb der regulären stationären Angebote möglich – andere junge Menschen dürfen dadurch nicht gefährdet werden </li>
                <li>Für junge Menschen mit körperlichen Behinderungen fehlt die behindertengerechte Infrastruktur</li>
            </ul>
        </div>

    </div>
</template>
