
import {defineComponent, ref} from "vue";
interface Option {
    label: string;
    value: string;
}
export default defineComponent({
    props: {
        id: String,
        label: String,
        identifier: String,
        options: Array
    },
    setup: () => {
        const selectedValue = ref<string | null>(null);
        return {
            selectedValue
        }
    },
    methods: {
        handleClick(e: Event, option: Option) {
            this.selectedValue = option.value;
        }
    }
});
