
import {Options, Vue} from 'vue-class-component';
import FormFieldContainerFieldset from '@/components/form/fieldcontainers/FormFieldContainerFieldset.vue';

@Options({
    props: {
        id: Number,
        label: String,
        children: Array
    },
    components: {
        FormFieldContainerFieldset
    }
})

export default class FormFieldContainerStep extends Vue {}
