import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from "@/App.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Start',
        component: App
    },
    {
        path: '/projekt',
        name: 'Projekt',
        component: App
    },
    {
        path: '/netzwerk',
        name: 'Netzwerk',
        component: App
    },
    {
        path: '/fall-melden',
        name: 'Fall melden',
        component: App
    },
    {
        path: '/ergebnisse',
        name: 'Ergebnisse',
        component: App
    },
    {
        path: '/wissenschaft',
        name: 'Wissenschaft',
        component: App
    },
    {
        path: '/kosten',
        name: 'Kosten',
        component: App
    },
    {
        path: '/downloads',
        name: 'Downloads',
        component: App
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior: (to) => {
        const identifier: string = to.path.substring(1, to.path.length);
        if (identifier !== '') {
            const targetSection: HTMLElement | null = document.querySelector('#' + identifier);
            if (targetSection) {
                return {
                    el: targetSection,
                }
            }
        }
        return {
            top: 0
        }
    },
    routes
})

export default router
