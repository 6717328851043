<template>
    <div class="o-company">
        <h5>Besonderheiten und Ressourcen der Einrichtung</h5>

        <div class="o-company__section">
            <h7>Breit gefächertes Angebot vollstationärer Hilfen mit</h7>
            <ul>
                <li>Intensivwohngruppen, </li>
                <li>verschiedene konzeptionellen Schwerpunkten/Konzepten zu ASS</li>
                <li>Trauma-Pädagogik</li>
                <li>massive Bindungsstörungen</li>
                <li>ION</li>
                <li>ISE-Projekte im In- und Ausland</li>
                <li>ISE-Projekt Stohrenhof </li>
                <li>Erlebnispädagogische Angebote, u.a. auch im Lippachhaus/Donautal</li>
            </ul>
            <h7>Breit gefächerte und sehr differenzierte Angebote im teilstationären und ambulanten Bereich</h7>
            <ul>
                <li>Internes großes Fachdienstteam mit Qualifikationen in Beratung, Diagnostik, Therapie</li>
                <li>intensive systemische Familienarbeit</li>
                <li>PIA-Sprechstunden vor Ort</li>
            </ul>
            <p>Gute Kooperation mit KJP</p>
        </div>

        <div class="o-company__section">
            <h6>Umsetzung</h6>
            <ul>
                <li>Zur Verfügung-Stellen von Räumlichkeiten</li>
                <li>Einbringen von Erfahrung im Gestalten von ISE-Maßnahmen vor Ort</li>
                <li>Verschiedene Standorte/ Erfahrungen mit Auszeitangeboten</li>
                <li>MA, die flexibel sind, Risiken eingehen und Lust haben, Neues auszuprobieren</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Angebot</h6>
            <h7>Aktuell:</h7>
            <ul>
                <li>Begleitete Auszeiten</li>
                <li>Beratung von Familien/ Einrichtungen/ des Gesamtsystemen zur Lösungsfindung</li>
                <li>Betreuung in allen unseren Angeboten(belegungsabhängig)</li>
                <li>Aufnahme in ION, wenn sinnvoll und Platz</li>
                <li>Orte zur Verfügung stellen</li>
            </ul>
            <h7>Mittelfristig:</h7>
            <ul>
                <li>MA-Pool für Flexible Hilfen/ 1:1-Maßnahmen</li>
            </ul>
            <h7>Mittel- und langfristig:</h7>
            <ul>
                <li>feste Standorte für Settings</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Mitarbeiterbegleitung</h6>
            <ul>
                <li>MA aus den eigenen Reihen für das Projekt gewinnen, begeistern</li>
                <li>Fortbildungen, Supervision, Beratung, Fallberatung, Coaching </li>
                <li>Verantwortung mittragen durch Bereitschaftsdienste und Fachdienste</li>
                <li>Deeskalationsstrategien entwickeln</li>
                <li>Gute Arbeitsbedingungen und Entlastungspläne, Auszeiten, zusätzliche MA zur Seite stellen, kreative Arbeitszeitmodelle, Fürsorge für die MAB</li>
                <li>Kreative Finanzierungsmodelle entwickeln</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Grenzen</h6>
            <ul>
                <li>Junge Menschen mit akuten Erkrankungen aus dem psychotischen Formenkreis</li>
                <li>Junge Menschen mit Suchterkrankungen</li>
            </ul>
        </div>

    </div>
</template>
