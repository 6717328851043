<template>
    <div class="o-company">
        <h5>Besonderheiten und Ressourcen der Einrichtung</h5>

        <div class="o-company__section">
            <ul>
                <li>Breit gefächertes Angebot vollstationärer Hilfen<br>incl. 2 Stabilisierungsgruppen für Kinder z.T.  mit psychiatrischen Krankheitsbilder</li>
                <li>Kriseninterventionsplätze, Clearing und ION</li>
                <li>Breit gefächerte und sehr differenzierte Angebote im teilstationären und ambulanten Bereich</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Qualitätssicherung durch</h6>
            <ul>
                <li>DGSF zertifizierte systemisch familienorientierte Einrichtung</li>
                <li>verschiedene Fachdienste mit systemischer Ausrichtung und heilpädagogischem Angebot</li>
                <li>Systemische Elternarbeit</li>
                <li>Trauma-Pädagogik-Konzept</li>
                <li>Kriseninterventionskonzept</li>
                <li>durch das interne Fortbildungsinstitut Main-Tauber-Institut</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Umsetzung</h6>
            <ul>
                <li>Beratungsangebote z.B. Systemisches Kooperationscoaching vor Ort</li>
            </ul>

        </div>

        <div class="o-company__section">
            <h6>Angebot</h6>
            <h7>Belegungsabhängig</h7>
            <ul>
                <li>Kurzzeitunterbringung mit Clearing</li>
                <li>Intensivplätze bzw. Stabilisierungsplätze für Kinder</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Grenzen</h6>
            <ul>
                <li>Fehlende Intensivplätze für Jugendliche</li>
                <li>Fehlende interne Schule</li>
                <li>Jugendliche mit schweren psychischen Erkrankungen, Suchterkrankungen betreuen</li>
                <li>Jugendliche mit geistiger Behinderung betreuen</li>
            </ul>
        </div>

    </div>
</template>
