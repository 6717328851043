
import {defineComponent, ref} from "vue";
interface Option {
    label: string;
    value: string;
}
export default defineComponent({
    props: {
        id: String,
        label: String,
        identifier: String,
        options: Array
    },
    setup: () => {
        const values = ref<string[]>([]);
        return {
            values
        }
    },
    methods: {
        handleClick(e: Event, option: Option) {
            const existingIndex = this.values.indexOf(option.value);
            if (existingIndex > -1) {
                this.values.splice(existingIndex, 1);
            } else {
                this.values.push(option.value);
            }
        }
    }
})
