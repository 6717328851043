<template>
    <div class="c-legal">
        <h2 class="c-legal__title">Impressum</h2>
        <section class="o-section">
            <h3 class="o-section__title">Angaben gemäß § 5 TMG</h3>
            <address>
                Mutpol - Diakonische Jugendhilfe Tuttlingen e. V.<br>
                Im Steinigen Tal 10/1<br>
                78532 Tuttlingen
            </address>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Vertreten durch</h3>
            <p>Dieter Meyer (Gesamtleiter / pädagogischer Vorstand von Mutpol)<br>
                Josh Feuerstein (Stellv. Gesamtleiter / Verwaltungsleiter)
            </p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Kontakt</h3>
            <p>
                Telefon: +49 (0) 7461 1706 0<br>
                Telefax: +49 (0) 7461 1706 17<br>
                E-Mail: info@mutpol.de
            </p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Registereintrag</h3>
            <p>
                Eintragung im Vereinsregister.<br>
                Registergericht: Amtsgericht Stuttgart<br>
                Registernummer: VR 450347
            </p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Umsatzsteuer</h3>
            <p>
                Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br>
                DE 264 469 843
            </p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Aufsichtsbehörde</h3>
            <address>
                Landratsamt Tuttlingen<br>
                Bahnhofstraße 100<br>
                78532 Tuttlingen
            </address>
            <p><a href="https://www.landkreis-tuttlingen.de/" target="_blank">www.landkreis-tuttlingen.de</a></p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Aufsichtsbehörde Jugendhilfe</h3>
            <address>
                Kommunalverband für Jugend und Soziales Baden-Württemberg<br>
                Lindenspürstraße 39,<br>
                70176 Stuttgart<br>
                Tel.: 0711 6375-0,<br>
                E-Mail: info@kvjs.de
            </address>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Aufsichtsbehörde Schule</h3>
            <address>
                Staatliches Schulamt Konstanz<br>
                Am Seerhein 6<br>
                78467 Konstanz<br>
                07531/80201-0<br>
                07531/80201-39<br>
                poststelle@ssa-kn.kv.bwl.de
            </address>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h3>
            <address>
                Mutpol - Diakonische Jugendhilfe Tuttlingen e. V.<br>
                Dieter Meyer<br>
                Im Steinigen Tal 10/1<br>
                78532 Tuttlingen
            </address>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Datenschutzbeauftragte Mutpol</h3>
            <p>Kontakt: datenschutz@dourado.eu</p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Streitschlichtung</h3>
            <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.
                Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <p>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Haftung für Inhalte</h3>
            <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Haftung für Links</h3>
            <p>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            </p>
            <p>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
        </section>
        <section class="o-section">
            <h3 class="o-section__title">Urheberrecht</h3>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
        </section>
        <p>Quelle: <a href="http://www.e-recht24.de/" target="_blank">www.e-recht24.de</a></p>
    </div>
</template>
