
import {Options, Vue} from 'vue-class-component';
import FormFieldText from '@/components/form/fields/FormFieldText.vue';
import FormFieldRadio from '@/components/form/fields/FormFieldRadio.vue';
import FormFieldDate from '@/components/form/fields/FormFieldDate.vue';
import FormFieldTextArea from '@/components/form/fields/FormFieldTextArea.vue';
import FormFieldYesNo from '@/components/form/fields/FormFieldYesNo.vue';
import FormFieldCheckboxes from '@/components/form/fields/FormFieldCheckboxes.vue';
import FormFieldCheckbox from '@/components/form/fields/FormFieldCheckbox.vue';
import FormFieldProblemRating from '@/components/form/fields/FormFieldProblemRating.vue';

@Options({
    props: {
        label: String,
        children: Array
    },
    components: {
        FormFieldText,
        FormFieldRadio,
        FormFieldDate,
        FormFieldTextArea,
        FormFieldYesNo,
        FormFieldCheckboxes,
        FormFieldCheckbox,
        FormFieldProblemRating
    }
})

export default class FormFieldContainerFieldset extends Vue {}
