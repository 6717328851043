
import {defineComponent, ref} from "vue";
import FormFieldText from '@/components/form/fields/FormFieldText.vue';
interface Option {
    label: string;
    value: number;
}
export default defineComponent({
    props: {
        id: String,
        label: String,
        identifier: String,
        specifyIf: {
            type: Number,
            required: false
        },
        specificationFieldLabel: {
            type: String,
            required: false
        },
        specificationFields: {
            type: Array,
            required: false
        }
    },
    components: {
        FormFieldText
    },
    setup: () => {
        const currentValue = ref<number | boolean>(false);
        const options: Option[] = [
            {
                label: 'Ja',
                value: 1
            },
            {
                label: 'Nein',
                value: 0
            }
        ]
        return {
            currentValue,
            options
        }
    },
    methods: {
        handleClick(e: Event, option: Option) {
            this.currentValue = option.value;
        }
    }
})
