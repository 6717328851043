
import {defineComponent, ref} from "vue";
import FormFieldContainerStep from "@/components/form/fieldcontainers/FormFieldContainerStep.vue";
import axios from "axios";
import SweetScroll from 'sweet-scroll';

export default defineComponent({
    components: {
        FormFieldContainerStep
    },
    setup: () => {
        const formConfiguration = ref([]);
        const formSteps = ref([]);
        const apiBaseUrl: string = (process.env.NODE_ENV === 'development') ? 'http://ariadne-microsite.ddev.site/be/' : 'https://api.ariadne-bw.de/';
        axios
            .get(apiBaseUrl + 'api/form/get')
            .then((response) => {
                formConfiguration.value = response.data;
                formSteps.value = response.data.steps;
            });
        const sweetScroll = new SweetScroll();
        const currentStep = ref(1);
        const submitted = ref(false);
        return {
            formConfiguration,
            formSteps,
            currentStep,
            apiBaseUrl,
            sweetScroll,
            submitted
        }
    },
    methods: {
        handleSubmit(e: Event): void {
            e.preventDefault();
            if (this.currentStep === this.formSteps.length) {
                if (e.currentTarget instanceof EventTarget) {
                    if (e.currentTarget instanceof HTMLFormElement) {
                        const form: HTMLFormElement = e.currentTarget;
                        const formData = new FormData(form);
                        const formProps = Object.fromEntries(formData);
                        axios.post(this.apiBaseUrl + 'api/form/submit', {
                            values: formProps
                        }).then(() => {
                            this.submitted = true;
                            this.$nextTick(() => {
                                const scrolltarget = this.$refs.rootElement as HTMLElement;
                                this.sweetScroll.toElement(scrolltarget, {
                                    duration: 500,
                                    easing: 'easeOutSine',
                                    offset: -200
                                });
                                setTimeout(() => {
                                    this.submitted = false;
                                    this.$emit('submitted');
                                }, 5000)
                            });
                        })
                    }
                }
            } else {
                if (this.currentStep < this.formSteps.length) {
                    this.currentStep++;
                    const scrolltarget = this.$refs.rootElement as HTMLElement;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.sweetScroll.toElement(scrolltarget, {
                                duration: 100,
                                easing: 'easeOutQuint',
                                offset: -200
                            });
                        }, 400);
                    })
                }
            }
        },
        goBack(): void {
            this.currentStep--;
        }
    }
});


