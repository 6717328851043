import { createStore } from 'vuex'

interface StoreState {
    activeSections: string[];
}

export default createStore<StoreState>({
  state: {
      activeSections: []
  },
  mutations: {
      activeSections: function (state, payload: string[]) {
          state.activeSections = payload;
      }
  },
  actions: {
      addActiveSection: (context, payload) => {
          const activeSections = context.state.activeSections;
          activeSections.push(payload);
          context.commit('activeSections', activeSections);
      },
      removeActiveSection: (context, payload) => {
          const activeSections = context.state.activeSections;
          const index = activeSections.findIndex((activeSection) => activeSection === payload);
          if (index > -1) {
              activeSections.splice(index, 1);
              context.commit('activeSections', activeSections);
          }
      }
  },
  modules: {
  }
})
