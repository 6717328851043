
import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {MainNavigationItem} from "@/interfaces";

export default defineComponent({
   setup: () => {
       const active = ref(false);
       const store = useStore();
       const mainNavigationItems: MainNavigationItem[] = [
           {
               name: 'Projekt',
               id: 'projekt'
           },
           {
               name: 'Netzwerk',
               id: 'netzwerk'
           },
           {
               name: 'Fall melden',
               id: 'fall-melden'
           },
           {
               name: 'Ergebnisse',
               id: 'ergebnisse'
           },
           {
               name: 'Wissenschaft',
               id: 'wissenschaft'
           },
           {
               name: 'Kosten',
               id: 'kosten'
           },
           {
               name: 'Downloads',
               id: 'downloads'
           }
       ];
       return {
           activeSections: computed(() => store.state.activeSections),
           active,
           mainNavigationItems
       }
   },
    methods: {
       handleClick() {
           this.active = !this.active;
       }
    }
});
