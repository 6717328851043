import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "o-field o-field--problem-rating" }
const _hoisted_2 = {
  key: 0,
  class: "o-field__label"
}
const _hoisted_3 = { class: "o-field__slider" }
const _hoisted_4 = { class: "o-slider" }
const _hoisted_5 = {
  class: "o-slider__slider",
  ref: "sliderElement"
}
const _hoisted_6 = {
  key: 0,
  class: "o-slider__selection"
}
const _hoisted_7 = { class: "o-field__selection__label" }
const _hoisted_8 = ["id", "name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, null, 512),
        (_ctx.selectedStepItem)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.selectedStepItem.label), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("input", {
        id: _ctx.id,
        name: _ctx.identifier,
        value: _ctx.selectedStepItem.value + ': ' + _ctx.selectedStepItem.label,
        type: "hidden"
      }, null, 8, _hoisted_8)
    ])
  ]))
}