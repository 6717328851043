<template>
    <div class="o-company">
        <h5>Besonderheiten und Ressourcen der Einrichtung</h5>

        <div class="o-company__section">
            <h7>Breit gefächertes Angebot vollstationärer Hilfen</h7>
            <ul>
                <li>28 stationäre Gruppen</li>
                <li>Angebote mit systemischer Ausrichtung (Mehr-Familien-Therapie/Familienaktivierungsmodule) </li>
                <li>Trauma-pädagogische Ausrichtung</li>
                <li>tiergestützte und erlebnispädagogische Angebote </li>
                <li>Erziehungsstellen</li>
                <li>Mutter- Kind-Gruppe</li>
                <li>Kleinkindgruppe ab 3</li>
                <li>Clearing- und Inobhutnahme-Gruppen</li>
                <li>Intensivgruppe für Jugendliche</li>
            </ul>
            <h7>Breit gefächerte und sehr differenzierte Angebote im teilstationären und ambulanten Bereich</h7>
            <ul>
                <li>Ambulante Angebote für Asperger Autismus</li>
                <li>Träger von zwei SBBZ ESENT</li>
            </ul>
            <h7>Qualität durch:</h7>
            <ul>
                <li>Eigenes Fortbildungsinstitut</li>
                <li>Psychologischer Fachdienst</li>
                <li>Video-Home-Training</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Umsetzung</h6>
            <ul>
                <li>Beratungsangebote/ VHT Beratung</li>
                <li>Differenziertes stationäres Angebot </li>
                <li>Spezifische Expertise bei Autismus</li>
                <li>Differenzierte Möglichkeiten der Beschulung</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Angebot</h6>
            <ul>
                <li>Stationäre Plätze, belegungsabhängig</li>
                <li>Fallberatung</li>
                <li>Expertise psychologischer Fachdienst</li>
                <li>Inobhutnahme</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Mitarbeiterbegleitung</h6>
            <ul>
                <li>Gute und perspektivische Personalführung</li>
                <li>Wissen um die Potentiale der Mitarbeiter*innen</li>
                <li>Förderung von Weiterbildung</li>
                <li>Hohe Autonomie im unmittelbaren Hilfeprozess</li>
                <li>Gute und gelingende Teamkultur entwickeln</li>
                <li>Rasche und spürbare Unterstützung bei Krisen</li>
                <li>Beratung durch Fachdienst/ Supervision/Leitungen</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Grenzen</h6>
            <ul>
                <li>Begrenzte Kapazitäten für stark ausagierende und sexuell stark übergriffige Kinder und Jugendliche</li>
                <li>Kein Intensivangebot für Kinder</li>
                <li>Schwere psychische Erkranken/ akute Suizidalität</li>
                <li>Keine suchtspezifischen Angebote</li>
                <li>Arbeit mit geistig und körperlich stark behinderten jungen Menschen</li>
            </ul>
        </div>

    </div>
</template>
