
import {Options, Vue} from 'vue-class-component';

@Options({
    props: {
        id: String,
        label: String,
        identifier: String
    }
})
export default class FormFieldDate extends Vue {
}
