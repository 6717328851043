
import {defineComponent} from "vue";
import Toggable from '@/components/ui/Toggable.vue';
import WaisenhausstiftungFreiburg from '@/components/content/network/WaisenhausstiftungFreiburg.vue';
import JugendhilfeCreglingen from '@/components/content/network/JugendhilfeCreglingen.vue';
import DiakonischeJugendhilfeHeilbronn from '@/components/content/network/DiakonischeJugendhilfeHeilbronn.vue';
import LinzgauKinderJugendhilfe from '@/components/content/network/LinzgauKinderJugendhilfe.vue';
import MutpolTuttlingen from '@/components/content/network/MutpolTuttlingen.vue';
import StiftungJugendhilfeStuttgart from '@/components/content/network/StiftungJugendhilfeStuttgart.vue';
import WaldhausHildrizhausen from '@/components/content/network/WaldhausHildrizhausen.vue';
import * as _ from 'lodash';

interface NetworkItem {
    title: string;
    subtitle: string;
    logo: string;
    componentName: string
}

export default defineComponent({
    props: {
        label: String,
        children: Array
    },
    components: {
        WaldhausHildrizhausen,
        StiftungJugendhilfeStuttgart,
        MutpolTuttlingen,
        LinzgauKinderJugendhilfe,
        DiakonischeJugendhilfeHeilbronn,
        JugendhilfeCreglingen,
        WaisenhausstiftungFreiburg,
        Toggable
    },
    setup: () => {
        const networkItems: NetworkItem[] = _.orderBy(
            [
                {
                    title: 'Waisenhausstiftung',
                    subtitle: 'Freiburg',
                    logo: 'Waisenhaus-Logo.png',
                    componentName: 'WaisenhausstiftungFreiburg'
                },
                {
                    title: 'Jugendhilfe',
                    subtitle: 'Creglingen',
                    logo: 'Logo_Juhi_2010_rgb7.jpg',
                    componentName: 'JugendhilfeCreglingen'
                },
                {
                    title: 'Diakonische Jugendhilfe',
                    subtitle: 'Heilbronn',
                    logo: 'DJHN_Logo_cmyk-2015.png',
                    componentName: 'DiakonischeJugendhilfeHeilbronn'
                },
                {
                    title: 'Kinder- und Jugendhilfe',
                    subtitle: 'Linzgau',
                    logo: 'linzgau-kinder-jugendhilfe.png',
                    componentName: 'LinzgauKinderJugendhilfe'
                },
                {
                    title: 'Mutpol',
                    subtitle: 'Tuttlingen',
                    logo: 'Mutpol-Logo.png',
                    componentName: 'MutpolTuttlingen'
                },
                {
                    title: 'Stiftung Jugendhilfe',
                    subtitle: 'Stuttgart',
                    logo: 'sjas.png',
                    componentName: 'StiftungJugendhilfeStuttgart'
                },
                {
                    title: 'Waldhaus',
                    subtitle: 'Hildrizhausen',
                    logo: 'Logo-Waldhaus_oU_340-CV.png',
                    componentName: 'WaldhausHildrizhausen'
                }
            ], (networkItem) => {
            return networkItem.title
        }, ['asc']);
        return {
            networkItems
        }
    }
})

