
import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import Section from '@/components/page/Section.vue';
import MultistepForm from '@/components/form/MultistepForm.vue';
import ToggablesAll from '@/components/content/network/ToggablesAll.vue';

export default defineComponent({
    components: {
        ToggablesAll,
        MultistepForm,
        Section
    },
    setup: () => {
        const showForm = ref(false);
        const store = useStore();
        const activeSections = computed(function () {
            return store.state.activeSections;
        });
        return {
            showForm,
            store,
            activeSections
        }
    },
    methods: {
        handleFormSubmitted(): void {
            this.showForm = false;
        },
        handleIntersectionChange(inViewport: boolean, elementId: string): void {
            if (inViewport) {
                this.store.dispatch('addActiveSection', elementId);
            } else {
                this.store.dispatch('removeActiveSection', elementId);
            }
        }
    }
});

