
import {Options, Vue} from 'vue-class-component';
import PageHead from '@/components/page/PageHead.vue';
import PageHero from '@/components/page/PageHero.vue';
import PageContent from '@/components/page/PageContent.vue';
import PageFooter from "@/components/page/PageFooter.vue";

@Options({
    components: {
        PageFooter,
        PageHead,
        PageHero,
        PageContent
    },
})
export default class App extends Vue {}
