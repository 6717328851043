import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "o-field o-field--checkbox" }
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "o-field__specifications"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "o-field__wrapper",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["o-field__checkbox", { 'o-field__checkbox--checked': _ctx.checked }])
      }, null, 2),
      _createElementVNode("input", {
        type: "hidden",
        name: _ctx.identifier,
        value: (_ctx.checked) ? '1' : '0'
      }, null, 8, _hoisted_2),
      _createElementVNode("label", {
        class: "o-field__label",
        for: _ctx.identifier
      }, _toDisplayString(_ctx.label), 9, _hoisted_3)
    ]),
    (_ctx.specifyIfSet && _ctx.checked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specificationFields, (specificationField, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(specificationField.component.name), _mergeProps({
              key: index,
              label: specificationField.label,
              id: specificationField.id,
              identifier: specificationField.identifier
            }, specificationField.component.props), null, 16, ["label", "id", "identifier"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}