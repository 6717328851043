import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-page__head" }
const _hoisted_2 = { class: "c-page__hero" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHead = _resolveComponent("PageHead")!
  const _component_PageHero = _resolveComponent("PageHero")!
  const _component_PageContent = _resolveComponent("PageContent")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageHead)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHero)
    ]),
    _createVNode(_component_PageContent),
    _createVNode(_component_PageFooter)
  ], 64))
}