import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "o-field o-field--checkboxes" }
const _hoisted_2 = {
  key: 0,
  class: "o-field__label"
}
const _hoisted_3 = { class: "o-field__options" }
const _hoisted_4 = { class: "o-field o-field--checkbox" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "o-field__option",
          key: index
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "o-field__wrapper",
              onClick: ($event: any) => (_ctx.handleClick($event, option))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["o-field__checkbox", { 'o-field__checkbox--checked': _ctx.values.indexOf(option.value) > -1 }])
              }, null, 2),
              _createElementVNode("label", {
                class: "o-field__label",
                for: _ctx.identifier + '_' + index
              }, _toDisplayString(option.label), 9, _hoisted_6)
            ], 8, _hoisted_5)
          ])
        ]))
      }), 128)),
      _createElementVNode("input", {
        type: "hidden",
        name: _ctx.identifier,
        value: _ctx.values.join('; ')
      }, null, 8, _hoisted_7)
    ])
  ]))
}