import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggable = _resolveComponent("Toggable")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networkItems, (networkItem, index) => {
    return (_openBlock(), _createBlock(_component_Toggable, {
      title: networkItem.title,
      subtitle: networkItem.subtitle,
      logo: networkItem.logo,
      key: index
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(networkItem.componentName)))
      ]),
      _: 2
    }, 1032, ["title", "subtitle", "logo"]))
  }), 128))
}