<template>
    <div class="o-company">
        <h5>Besonderheiten und Ressourcen der Einrichtung</h5>

        <div class="o-company__section">
            <h7>Breit gefächertes Angebot vollstationärer Hilfen (6 bis 21 Jahre)</h7>
            <ul>
                <li>incl. Intensivgruppe für Jungen ab 14 Jahren, alternativ zu einer geschlossenen Unterbringung</li>
                <li>ISE Angebote und ISE-Projekte in Polen und Schwäbische Alb mit Fernbeschulung und Ausbildungsmöglichkeiten</li>
            </ul>
            <p>Breit gefächerte und sehr differenzierte Angebote im teilstationären und ambulanten Bereich</p>
            <h7>Qualitätssicherung durch</h7>
            <ul>
                <li>Systemische Elternarbeit</li>
                <li>Trauma-Pädagogik-Konzept</li>
                <li>Suchtpräventionskonzept</li>
                <li>sexualpädagogisches Konzept</li>
                <li>Antigewalttrainings-Konzept</li>
                <li>aufsuchende Familienarbeit</li>
                <li>Beschäftigungskonzept für Schulverweigerer</li>
                <li>Erlebnispädagogik</li>
                <li>Kriseninterventionskonzept, Clearing und ION</li>
                <li>Kooperationsvertrag mit KJP</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Umsetzung</h6>
            <ul>
                <li>systemisches Coaching, Beratung und Familienarbeit</li>
                <li>Trauma-Pädagogik, Suchtprävention, sexualpädagogisches Konzept</li>
                <li>pferdegestütztes Coaching</li>
                <li>erlebnispädagogische Angebote</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Angebot</h6>
            <h7>Schnell:</h7>
            <ul>
                <li>ION mit Kriseninterventionsplätzen und Clearing (flexibel, zeitnah, lösungsorientiert)</li>
            </ul>
            <h7>Mittel bis langfristig:</h7>
            <ul>
                <li>Intensivgruppe für Jungen ab 14 Jahren</li>
                <li>ISE Projekte im In- und Ausland</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Mitarbeiterbegleitung</h6>
            <ul>
                <li>Gezielte Werbung für eine pädagogische Arbeit in sehr herausfordernden Situationen</li>
            </ul>
            <h7>Personal gestützt durch:</h7>
            <ul>
                <li>sehr guten Personalschlüssel, flexiblen Dienstplan, Auszeiten, Supervision, Coaching, durch gehende Rufbereitschaften durch Leitung</li>
                <li>Rückendeckung durch Leitung und Fachdienste</li>
            </ul>
        </div>

        <div class="o-company__section">
            <h6>Grenzen</h6>
            <ul>
                <li>Pädagogische Arbeit mit jungen Menschen mit Behinderung</li>
                <li>Manifeste Suchterkrankung</li>
                <li>Schwere akute psychische Krisen und Suizidgefährdung</li>
            </ul>
        </div>

    </div>
</template>
