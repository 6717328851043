import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "o-field o-field--radio" }
const _hoisted_2 = {
  key: 0,
  class: "o-field__label"
}
const _hoisted_3 = { class: "o-field__options" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["name", "value", "id"]
const _hoisted_6 = ["for"]
const _hoisted_7 = {
  key: 1,
  class: "o-field__specifications"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "o-field__option",
          key: index,
          onClick: ($event: any) => (_ctx.handleClick($event, option))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["o-field__option__radio", { 'o-field__option__radio--checked': _ctx.currentValue === option.value }])
          }, null, 2),
          _createElementVNode("input", {
            class: "o-field__option__input",
            type: "hidden",
            name: _ctx.identifier,
            value: _ctx.currentValue,
            id: _ctx.identifier+(index+1)
          }, null, 8, _hoisted_5),
          _createElementVNode("label", {
            class: "o-field__option__label",
            for: _ctx.identifier+(index+1)
          }, _toDisplayString(option.label), 9, _hoisted_6)
        ], 8, _hoisted_4))
      }), 128))
    ]),
    (_ctx.specifyIf === _ctx.currentValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specificationFields, (specificationField, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(specificationField.component.name), _mergeProps({
              key: index,
              label: specificationField.label,
              id: specificationField.id,
              identifier: specificationField.identifier
            }, specificationField.component.props), null, 16, ["label", "id", "identifier"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}