<template>
    <div class="o-hero">
        <div class="o-hero__top">
            <img src="@/assets/images/ariadne-logo-white.svg"
                 class="o-hero__logo"
                 alt="ARIADNE Logo" />
            <p class="o-hero__text">Wer den Faden von Ariadne gereicht bekommt, kann sich in den verwirrenden Gängen des Labyrinthes sicher bewegen und auch wieder herausfinden. </p>
        </div>
        <div class="o-hero__bottom">
            <img src="@/assets/images/ariadne-signet.svg"
                 class="o-hero__signet"
                 alt="ARIADNE Logo" />
            <router-link to="/projekt"
                         class="o-hero__down"><span class="o-hero__down__icon o-icon o-icon--arrow-down"></span></router-link>
        </div>
    </div>
</template>
